import { render, staticRenderFns } from "./WarehouseDetails.vue?vue&type=template&id=5026d7e2&scoped=true"
import script from "./WarehouseDetails.vue?vue&type=script&lang=js"
export * from "./WarehouseDetails.vue?vue&type=script&lang=js"
import style0 from "./WarehouseDetails.vue?vue&type=style&index=0&id=5026d7e2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5026d7e2",
  null
  
)

export default component.exports