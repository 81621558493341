// initial state
const state = {
  loadTypes: [],
  loadTypeMap: {},
  pagination: {}
};

// getters
const getters = {
  loadTypes(state) {
    return state.loadTypes;
  },
  loadTypesByIdArray: state => loadTypeIds => {
    return state.loadTypes.filter(loadType => {
      return loadTypeIds.includes(loadType.id);
    });
  },
  loadTypeName:
    () =>
    (loadType, includeDuration = true, includeDirection = false) => {
      let loadTypeName = loadType.name;
      loadTypeName += includeDuration ? ` (${loadType.duration_min} min)` : '';
      loadTypeName += includeDirection ? ` - ${loadType.direction.toUpperCase()}` : '';
      return loadTypeName;
    }
};

// actions
const actions = {
  async getLoadTypesWithWarehouses({ commit, dispatch }, filters = null) {
    const response = await axios.get(`loadtype`, {
      params: filters || state.filters
    });

    if (!response?.data) {
      return false;
    }

    const loadTypes = await dispatch('getLoadTypes', filters);
    const warehouseResponse = await dispatch('Warehouses/getWarehouses', {}, { root: true });
    const loadTypesByLocation = {};

    if (warehouseResponse?.warehouses) {
      warehouseResponse.warehouses.forEach(warehouse => {
        if (!loadTypesByLocation[warehouse.id]) {
          loadTypesByLocation[warehouse.id] = [];
        }
        loadTypesByLocation[warehouse.id] = {
          loadTypeIds: [...new Set(warehouse.docks.map(dock => dock.loadTypeIds).flat())],
          warehouse
        };
      });
    }

    loadTypes.map(loadType => {
      loadType.warehouses = [];
      Object.values(loadTypesByLocation).forEach(item => {
        if (item.loadTypeIds.includes(loadType.id)) {
          loadType.warehouses.push(item.warehouse);
        }
      });
      return loadType;
    });

    response.data.data = loadTypes;
    commit('setApiResponseData', response.data);
    commit('setFilters', filters || state.filters);

    return loadTypes;
  },
  async getLoadTypes({ commit }, filters = null) {
    const response = await axios.get(`loadtype`, {
      params: filters || state.filters
    });

    if (response?.data) {
      commit('setApiResponseData', response.data);
      commit('setFilters', filters || state.filters);
    }

    return state.loadTypes ?? [];
  },
  async createLoadType({}, loadType) {
    return await axios.post('loadtype', loadType);
  },
  async deleteLoadType({}, loadType) {
    return await axios.delete(`loadtype/${loadType.id}`);
  },
  async getAvailability({}, { id, params }) {
    return await axios.post(`loadtype/${id}/get-availability`, params);
  }
};

// mutations
const mutations = {
  setLoadTypes(state, loadTypes) {
    state.loadTypes = loadTypes;

    loadTypes.forEach(lt => {
      state.loadTypeMap[lt.id] = lt;
    });
  },
  setApiResponseData(state, response) {
    state.loadTypes = response.data;
    state.pagination = {
      page: response.page,
      pageCount: response.pageCount,
      total: response.total
    };
  },
  setFilters(state, filters) {
    state.filters = filters;
  }
};

export default {
  namespaced: true,
  name: 'LoadTypes',
  state,
  getters,
  actions,
  mutations
};
