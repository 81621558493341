import util from '@satellite/plugins/util';
import { isMobile } from '@satellite/plugins/util';

import { AppointmentStatus } from '@satellite/../nova/core';

const joinString =
  'join=dock&join=dock.warehouse&join=loadType||name,duration_min,allowCarrierScheduling,direction&join=user&join=org';

// initial state
const state = {
  appointments: []
};

// getters
const getters = {
  appointments(state) {
    return state.appointments;
  },
  warehouseAppointments: state => warehouseId => {
    return state.appointments.filter(appointment => {
      return appointment.dock.warehouseId === warehouseId;
    });
  },
  appointmentJoins:
    () =>
    (asArray = false) => {
      return !asArray ? joinString : joinString.split(/&?join=/).filter(s => !!s);
    }
};

// actions
const actions = {
  async getAppointmentById({}, appointmentId) {
    let response = await axios.get(`appointment/${appointmentId}?${joinString}`);
    return response?.data.data;
  },
  async getAppointments({ commit }, params) {
    let response = await axios.get(`appointment?${joinString}`, { params: params });
    let appointments = [];
    if (response && response.data) {
      commit('setAppointments', response.data.data);
      appointments = response.data.data;
    }

    return appointments;
  },
  async createAppointment({ dispatch }, data) {
    const requestConfig = data.requestConfig;
    const isClone = data.isClone;
    delete data.requestConfig;
    delete data.isClone;
    let response = await axios.post(
      `/appointment${isClone ? '?bypassCustomFieldsValidation=true' : ''}`,
      data,
      requestConfig
    );

    dispatch('triggerSocketEvent', { response });

    return response?.data;
  },
  async createReserve({ dispatch }, data) {
    const response = await axios.post('/appointment/reserve', data);

    dispatch('triggerSocketEvent', { response });

    return response?.data;
  },
  async deleteReserve({ dispatch }, data) {
    const response = await axios.delete(`appointment/${data.id}`);

    dispatch('triggerSocketEvent', { response, appointment: data });

    return response?.data;
  },
  async transferReserves({ dispatch }, { userId, targetUserId }) {
    const response = await axios.patch(`/appointment/reserve/transfer-all`, {
      userId,
      targetUserId
    });

    dispatch('triggerSocketEvent', { response });

    return response?.data;
  },
  async transferAppointments({ dispatch }, { userId, targetUserId }) {
    const response = await axios.patch(`/appointment/transfer-all`, {
      userId,
      targetUserId
    });

    dispatch('triggerSocketEvent', { response });

    return response?.data;
  },
  async cancelAppointment({ dispatch }, { id, reason, requestConfig = null }) {
    const appointment = await this.dispatch('Appointments/getAppointmentById', id);
    let response = await axios.patch(
      `appointment/${id}`,
      {
        status: this.$app.novaCore.AppointmentStatus.Cancelled,
        notes: reason
          ? `${appointment.notes ?? ''}<br/>Cancellation Reason: ${reason}`
          : appointment.notes ?? ''
      },
      requestConfig
    );

    dispatch('triggerSocketEvent', { response, appointment });

    return response?.data;
  },
  async triggerSocketEvent({}, { response, appointment = {} }) {
    if (this.$app?.$eventHub && response?.status < 400 && response?.data?.data) {
      const action = response.data.action || 'update';
      this.$app.$eventHub.$emit(`${action}-Appointment`, {
        ...appointment,
        ...response.data.data
      });
    }
  },
  trackMixpanelEvent({ rootState }, payload) {
    try {
      const data = {
        'Org ID':
          payload.appointment?.dock.warehouse.org?.id ||
          payload.appointment?.orgId ||
          rootState.Auth.me?.orgId,
        'Org Name': payload.appointment?.dock.warehouse.org?.name,
        'Warehouse ID': payload.appointment?.dock.warehouse?.id,
        'Warehouse Name': payload.appointment?.dock.warehouse?.name,
        'Appointment ID': payload.appointment?.id,
        'Appointment Start': payload.appointment?.start,
        'Appointment Ref #': payload.appointment?.refNumber,
        'Appointment Tags': payload.appointment?.tags,
        'Dwell Time': 'N/A',
        'Arrival Time': 'N/A',
        'Current Status': payload.appointment?.status || 'N/A',
        'Update Type': payload.change,
        'Appointment ETA': payload.appointment?.eta,
        'Changed By': payload.changedBy ?? 'Warehouse User',
        'Entry Point': isMobile() ? 'Mobile Details Page' : payload.entryPoint ?? 'Details Modal'
      };

      if (payload.appointment?.statusTimeline) {
        const completedTime = payload.appointment.statusTimeline[AppointmentStatus.Completed];
        const arrivedTime = payload.appointment.statusTimeline[AppointmentStatus.Arrived];

        if (completedTime) {
          data['Dwell Time'] = util.getReadableDuration(
            arrivedTime,
            completedTime,
            'America/Phoenix',
            ''
          );
        }

        if (arrivedTime) {
          data['Arrival Time'] = arrivedTime;
        }
      }
      let event = this.$app.mixpanel.events.MODULE.APPOINTMENT.UPDATED;

      if (payload.change === 'Cancelled') {
        event = this.$app.mixpanel.events.MODULE.APPOINTMENT.CANCELLED;
      }

      if (payload.change === 'Tags') {
        event = this.$app.mixpanel.events.MODULE.APPOINTMENT.TAGS;
      }

      if (payload.change === 'Details') {
        event = this.$app.mixpanel.events.MODULE.APPOINTMENT.DETAILS;
      }

      if (payload.change.startsWith('Recurrence')) {
        event = this.$app.mixpanel.events.MODULE.APPOINTMENT.RECURRENCE;
      }

      if (payload.change.startsWith('Status:')) {
        event = this.$app.mixpanel.events.MODULE.APPOINTMENT.STATUS;
      }

      this.$app.mixpanel.track(event, data);
    } catch (e) {
      console.log('MP Event Error', e);
    }
  }
};

// mutations
const mutations = {
  setAppointments(state, appointments) {
    state.appointments = appointments;
  },
  setAllAppointments(state, appointments) {
    state.allAppointments = appointments;
  },
  insertAppointment(state, newAppointment) {
    let index = state.appointments.findIndex(appointment => appointment.id === newAppointment.id);
    let appointments = [...state.appointments];
    if (index >= 0) {
      appointments[index] = newAppointment;
    } else {
      appointments.push(newAppointment);
    }

    state.appointments = newAppointment;
  }
};

export default {
  namespaced: true,
  name: 'Appointments',
  state,
  getters,
  actions,
  mutations
};
