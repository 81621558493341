var render = function render(){var _vm=this,_c=_vm._self._c;return _c('m-field',{class:{ 'flex-1': !_vm.width },attrs:{"status":_vm.hasError ? 'danger' : 'default'}},[(_vm.label)?_c('m-field-label',{attrs:{"slot":"label","data-testid":`${_vm.testId}-label`},slot:"label"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('vue-tel-input',_vm._g({ref:"telInput",style:(_vm.computedWidth),attrs:{"mode":"international","dropdownOptions":{
      showDialCodeInSelection: true,
      showFlags: false,
      showDialCodeInList: true
    },"inputOptions":{
      placeholder: _vm.$attrs.placeholder ?? 'Enter a phone number'
    },"valid-characters-only":"","value":_vm.lazyValue,"all-countries":_vm.countryOptions},on:{"focus":_vm.handleFocus,"blur":_vm.handleBlur,"country-changed":[_vm.validateInput,val => (_vm.selectedCountry = val)]},scopedSlots:_vm._u([{key:"arrow-icon",fn:function(){return [_c('v-icon',[_vm._v("chevron-down")])]},proxy:true}])},{
      ..._vm.$listeners,
      input: _vm.onInput
    })),_c('m-field-hint',{attrs:{"data-testid":`${_vm.testId}-errors`}},[_c('span',[_vm._v(_vm._s(_vm.errorBucket.join(', ')))]),(_vm.errorBucket.length && _vm.hint)?_c('span',[_vm._v(".")]):_vm._e(),(_vm.hint)?_c('span',{class:{ 'p-l-1': _vm.errorBucket.length }},[_vm._v(_vm._s(_vm.hint))]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }