import AxiosPlugin from '@satellite/plugins/AxiosPlugin';
import config from '@/config/config';
import companyService from '@satellite/services/company-service';
import { isMrPreview, NovaErrorCode } from '@satellite/../nova/core';

// initial state
const state = {
  accessToken: null,
  me: null,
  previewWarehouseId: null
};

// getters
const getters = {
  accessToken(state) {
    return (
      state.accessToken ??
      sessionStorage.getItem('access_token') ??
      localStorage?.getItem('access_token')
    );
  },
  isMrPreview(state) {
    return isMrPreview(state.me);
  },
  previewWarehouseId() {
    return sessionStorage.getItem('previewWarehouseId');
  }
};

// actions
const actions = {
  async login({ dispatch, commit }, { creds, requestConfig }) {
    dispatch.logout;
    let response = await axios
      .post(
        'auth/login',
        {
          email: creds.email,
          password: creds.password
        },
        requestConfig
      )
      .catch(e => {
        if (e.response?.data?.errorCode === NovaErrorCode.UnverifiedEmail) {
          this.$app.notify('Please check your email account', 'error', {
            title: 'Your account is not activated'
          });
        } else {
          this.$app.notify(e?.response?.data?.message, 'error');
        }
        return e.response;
      });

    if (response?.status === 200) {
      localStorage.setItem('access_token', response.data.access_token);
      await commit('setAccessToken', response.data.access_token);
      await dispatch('getMe');
    }

    return response;
  },
  logout({ state }) {
    this.$app.util.clearLocalStorage();
    this.$app.util.clearSessionStorage(['access_token']);
    state.accessToken = null;
  },
  setInitialAccessToken({ commit, getters }) {
    commit('setAccessToken', getters.accessToken);
  },
  async getMe({ commit }) {
    const response = await axios.get('auth/me');
    if (response?.data) {
      const user = response.data;
      const company = user.companyId
        ? await companyService.getCompanyById(user.companyId, {}, { fields: ['name'] })
        : null;
      user.company = company;
      await commit('setMe', user);
      await commit('setRolePermissions');
    } else {
      localStorage.removeItem('access_token');
      return;
    }

    return response;
  },
  async getAuthenticatedUser({ getters }) {
    const token = getters.accessToken;
    let user = await getters.me;
    let authorized = token && Boolean(user?.id);

    if (!authorized && token) {
      try {
        user = await this.dispatch('Auth/getMe', token).then(response => response.data);
      } catch (e) {
        user = null;
      }
    }

    return user;
  },
  async logInToWarehousePortal({ state, commit }, routeJSON) {
    routeJSON = routeJSON ?? JSON.stringify({ name: 'appointments' });

    // Make sure luna does not store the helios token
    const token = String(state.accessToken);
    await commit('setAccessToken', null);

    await window.location.replace(`${config.helios_url}?token=${token}&nextRoute=${routeJSON}`);
  },
  async setPreviewWarehouseId({ commit }, warehouseId) {
    await sessionStorage.setItem('previewWarehouseId', warehouseId);
    commit('setPreviewWarehouseId', warehouseId);
  }
};

// mutations
const mutations = {
  setAccessToken(state, token) {
    AxiosPlugin.setAccessToken(token);
    setTimeout(() => this.$app.sockets.connect(token), 0);

    if (!token) {
      localStorage.removeItem('access_token');
      sessionStorage.removeItem('access_token');
      state.accessToken = null;
      return;
    }

    sessionStorage.setItem('access_token', token);
    state.accessToken = token;
  },
  setPreviewWarehouseId(state, warehouseId) {
    state.previewWarehouseId = warehouseId;
  }
};

export default {
  namespaced: true,
  name: 'Auth',
  state,
  getters,
  actions,
  mutations
};
