import VueRouter from 'vue-router';
import store from './store';
import config from '@/config/config';
import routeHelpers from '@satellite/helpers/routes';
import { isCarrierUser } from '@satellite/../nova/core';

// Routes are located in the individual module folders
// Import all of the module routes files.
function loadLocalRoutes() {
  const context = require.context('@/modules', true, /routes.js$/i);
  return context
    .keys()
    .map(context) // import module
    .map(m => m.default); // get `default` export from each resolved module
}

// function loadSatelliteRoutes() {
//   const context = require.context('@satellite/modules', true, /routes.js$/i);
//   return context
//     .keys()
//     .map(context) // import module
//     .map(m => m.default); // get `default` export from each resolved module
// }

const moduleRoutes = [...loadLocalRoutes()];
let routes = [
  // Moduleless routes can go here
  {
    path: '/',
    name: 'home',
    redirect: { name: 'warehouse.select' },
    meta: {
      requiresAuth: false
    }
  }
];

routes = routeHelpers.registerRoutes(routes, moduleRoutes);

const router = new VueRouter({
  mode: 'history',
  routes: routes
});

const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
};

router.beforeEach(async (to, from, next) => {
  if (!store.getters['App/s3BaseUrl']) {
    await store.dispatch('App/getAppEnvironment', {}, { root: true });
  }
  /**
   * We needed a way to log in a user from the warehouse portal for preview mode
   */
  const token = to.query?.token;
  if (token && token !== 'undefined') {
    await store.dispatch('Auth/loginAs', token, 'loginPageWithTokenParam');
    const parsedNextRoute = to.query?.nextRoute ? JSON.parse(to.query?.nextRoute) : null;
    const nextRoute = parsedNextRoute ?? { name: 'book' };

    const warehouseId = parsedNextRoute?.params?.warehouseId;
    if (warehouseId) {
      await store.commit('Auth/setPreviewWarehouseId', warehouseId);
    }

    // Ensure this handles if the user doesn't actually authenticate
    return next(nextRoute);
  }

  // Check if the from route is dirty (has unsaved data), if it is then ask user if they want to proceed
  if (await routeHelpers.checkDirty(store, from)) {
    return;
  }

  routeHelpers.setPageTitle(store, to, config.application_name);

  const authRequired = routeHelpers.isAuthRequired(to);

  if (!authRequired) {
    routeHelpers.setAppLoadedTrue(store);
    return next();
  }

  const authenticatedUser = await store.dispatch('Auth/getAuthenticatedUser');
  if (authenticatedUser) {
    if (!isCarrierUser(authenticatedUser)) {
      await store.dispatch('Auth/logInToWarehousePortal');
      return next();
    }
    // Force redirect to company registration for auth pages if they don't have one already
    if (!authenticatedUser.companyId && to.name !== 'register.company') {
      next({ name: 'register.company' });
    }
    return next();
  } else {
    routeHelpers.setAppLoadedTrue(store);
    next({ name: 'login', query: { redirectPath: window.location.pathname } });
  }
});

export default router;
